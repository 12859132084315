<template>
  <div>
    <div class="content">
      <div class="logoImg">
        <img src="../assets/img/logo.png" alt="" />
      </div>
      <div class="boss">
        <div v-if="pid">
          <div v-if="pigDetal.id">
            <!-- <div class="video">
              <img :src="pigDetal.image" alt="" />
            </div> -->
            <div class="video">
              <!-- <img :src="pigDetal.image" alt="" /> -->
              <div class="videos" id="video-container"></div>
            </div>
            <div class="info">
              <ul class="ulInfo">
                <li>
                  猪崽编号： <span class="left">{{ pigDetal.pig_num }}</span>
                </li>
                <li>
                  出生时间：<span class="left">{{ pigDetal.month }} 月</span>
                </li>
                <!-- <li>
                  当前重量：<span class="left">{{ pigDetal.weight }} KG</span>
                </li> -->
                <li>宰杀时间：<span class="left">生长中...</span></li>
              </ul>
              <div class="certificate">
                <van-tabs
                  v-model="active"
                  title-active-color="#FE1E00"
                  title-inactive-color="#FBEBD3"
                >
                  <van-tab title="防疫记录">
                    <div class="infoText" v-if="pigDetal.prevent">
                      <div
                        class="content"
                        v-for="(item, index) in pigDetal.prevent"
                        :key="index"
                      >
                        <p class="time">{{ item.add_time }}</p>
                        <p class="medicine">{{ item.prevention }}</p>
                      </div>
                    </div>
                    <div v-else>
                      <div class="none">暂无数据</div>
                    </div>
                  </van-tab>
                  <van-tab title="喂养记录">
                    <div v-if="feedList.length != 0">
                      <div
                        class="div-content"
                        v-for="item in feedList"
                        :key="item[0].date"
                      >
                        <div>
                          <p class="time">{{ item[0].date }}</p>
                          <div class="infos" v-for="dd in item" :key="dd.id">
                            <p class="info_time">{{ dd.time }}</p>
                            <div class="left-right">
                              <div class="displays">
                                <p
                                  class="info_text"
                                  v-for="val in dd.feed"
                                  :key="val.id"
                                >
                                  <span
                                    class="fuhao"
                                    v-if="
                                      val.cate_id != 2 &&
                                      val.cate_id != 1 &&
                                      val.cate_id != 3
                                    "
                                  >
                                    {{ val.name + '：' + val.weight + '克' }}
                                  </span>
                                  <span class="fuhao" v-if="val.cate_id == 1">
                                    {{ val.name + '：' + val.weight + '份' }}
                                  </span>
                                  <span class="fuhao" v-if="val.cate_id == 2">
                                    {{ val.name }}
                                  </span>
                                  <span class="fuhao" v-if="val.cate_id == 3">
                                    {{ val.name }}
                                  </span>
                                </p>
                              </div>
                              <div
                                class="clickviope"
                                @click="clickviope(dd.video_url)"
                                v-if="dd.video_url"
                              >
                                喂养视频
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <div class="none">暂无数据</div>
                    </div>
                  </van-tab>
                </van-tabs>
              </div>
              <p
                class="more"
                v-if="active == 1 && feedList.length != 0"
                @click="weiyangjilu"
              >
                查看全部喂养记录
              </p>
            </div>
            <div class="btn">
              <img src="../assets/img/xiazai.png" alt="" @click="clickBtn" />
            </div>
          </div>
          <!-- <div class="none" v-else>暂无数据</div> -->
        </div>
        <div v-else class="lookUp">
          <van-field
            v-model="number"
            type="number"
            placeholder="请输入编号数字"
          />
          <div>
            <img src="../assets/img/cha.png" alt="" @click="check" />
          </div>
        </div>
      </div>
    </div>
    <p class="titile" v-show="titleShow">{{ msg }}</p>
    <van-dialog v-model="viopeshow" :show-confirm-button="false">
      <div class="pot">
        <div class="tc-viope-box">
          <div class="weiyang">喂养视频</div>
          <div class="bfvipoe">
            <video-player
              ref="myVideo"
              :playsinline="true"
              v-show="viopeshow"
              :options="playerOptions"
            >
            </video-player>
          </div>
        </div>
        <img
          src="../assets/img/close.png"
          alt=""
          class="close"
          @click="closevipoe"
        />
      </div>
    </van-dialog>
    <van-popup v-model="show">
      <div class="bossBox">
        <p class="verify">手机号绑定验证</p>
        <div>
          <van-cell-group>
            <van-field
              v-model="phone"
              clearable
              :border="false"
              type="number"
              maxlength="11"
              placeholder="请填写手机号"
              left-icon="http://static.yhaocang.com/img/pages/pc/phone.png"
            />
          </van-cell-group>
        </div>
        <div class="yanzheng">
          <van-cell-group class="cell">
            <van-field
              v-model="code"
              :border="false"
              placeholder="验证码"
              left-icon="http://static.yhaocang.com/img/pages/pc/ma.png"
            />
          </van-cell-group>
          <div v-show="codeShow" class="song" @click="getCode">
            获取验证码
          </div>
          <div v-show="!codeShow" class="song">
            {{ yzmTitle }}
          </div>
        </div>
        <div class="sure" @click="isTrue">下载</div>
      </div>
    </van-popup>
    <p class="titile" v-show="titleShow">{{ msg }}</p>
    <div v-if="isweixin" class="isWeixinOpen">
      <img src="../assets/img/zao.png" alt="" />
    </div>
    <t-captcha-js src="https://ssl.captcha.qq.com/TCaptcha.js"></t-captcha-js>
  </div>
</template>

<script>
import {
  File,
  record,
  getToken,
  getDownUrl,
  sendSms,
  bindFriend
} from '../api/index'
import { videoPlayer } from 'vue-video-player'
import 'video.js/dist/video-js.css'
import 'videojs-contrib-hls'
import EZUIKit from 'ezuikit-js'
export default {
  components: {
    't-captcha-js': {
      render(createElement) {
        return createElement('script', {
          attrs: { type: 'text/javascript', src: this.src }
        })
      },
      props: {
        src: { type: String, required: true }
      }
    },
    videoPlayer
  },
  data() {
    return {
      active: 0,
      pigDetal: {},
      feedList: [],
      titleShow: false,
      msg: '',
      url: '',
      number: '',
      accessToken: '',
      pid: this.$route.query.pid,
      viopeshow: false,
      timing: null,
      timer: null,
      playerOptions: {
        autoplay: true,
        muted: true, // 默认情况下将会消除任何音频。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            // type: 'application/x-mpegURL',
            type: 'video/mp4',
            src: '' // url地址
          }
        ],
        controlBar: {
          timeDivider: false, // 当前时间和持续时间的分隔符
          durationDisplay: false, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: false // 是否显示全屏按钮
        }
      },
      show: false,
      phone: '',
      code: '',
      yzmTitle: '',
      // titleShow: false,
      codeShow: true, //图片验证
      user: '',
      isweixin: false,
      dialogShow: false, //提示
      index: 1,
      message: '',
      isios: false,
      isandriod: false,
      andriod: '',
      ios: ''
    }
  },
  async created() {
    if (this.pid) {
      await this.initDetail()
      await this.recordJl()
      await this.appSource()
      await this.getToken()
    }
  },
  methods: {
    // 视频初始化
    async getToken() {
      // 发送 POST 请求
      const res = await getToken()
      if (res.data.code != 200) return
      this.accessToken = res.data.data.accessToken
      console.log(res, '视频getToken99')
      console.log(res.data.data.accessToken, '视频getToken')
      //视频初始化
      console.log(this.url, 'this.urlthis.url')
      this.player = await new EZUIKit.EZUIKitPlayer({
        autoplay: true, //自动播放
        id: 'video-container', // 视频容器ID
        accessToken: this.accessToken,
        url: this.pigDetal.monitor_url,
        audio: 0
      })
      this.timer = setTimeout(() => {
        this.timing += 30000
        console.log(this.timing, '时间')
        if (this.timing >= 30000) {
          this.stopVideo()
          console.log('结束播放')
          this.networkAnomaly = true
          clearInterval(this.timer)
          return
        }
      }, 30000)
    },
    // 停止播放
    stopVideo() {
      this.player.stop()
    },
    // playVideo() {
    //   this.player.play()
    // },
    //获取猪崽档案
    async initDetail() {
      this.$store.commit('SET_P_ID')
      let data = {
        p_id: this.pid
      }
      let res = await File(data)
      if (res.data.code != 200) return
      console.log(res.data.data, '档案')
      this.pigDetal = res.data.data
      this.url = res.data.data.monitor_url
    },
    // 查询
    check() {
      if (this.number) {
        this.$router.push({
          url: '/file',
          query: {
            pid: this.number
          }
        })
        this.$router.go(0)
      } else {
        console.log('wu')
        this.msg = '请输入编号'
        this.titleShow = true
        setTimeout(() => {
          this.titleShow = false
        }, 2500)
      }
    },
    //喂养记录
    async recordJl() {
      let data = {
        ls_id: this.pid,
        page: 1,
        days: 3
      }
      let res = await record(data)
      if (res.data.code != 200) return
      this.feedList = res.data.data
    },
    clickviope(e) {
      console.log(e, '视频')
      this.viopeshow = true
      this.playerOptions.sources[0].src = e
      // this.stopVideo()
    },
    closevipoe() {
      this.viopeshow = false
      this.playerOptions.sources[0].src = ''
    },
    // clickBtn() {
    //   // this.$router.push('/friend')
    // },
    weiyangjilu() {
      // $router.push('/more')
      this.$router.push({
        path: '/more',
        query: { p_id: this.$route.query.pid }
      })
    },
    //
    // 剪贴板
    dataProcessing(val) {
      // this.message = '#' + val + '#'
      this.message = val
    },
    // 剪贴板
    doCopy(val) {
      this.dataProcessing(val)
      this.$copyText(this.message).then(
        function (e) {
          console.log(e, 9999)
          console.log(e.text, 9999)
        },
        function (e) {
          console.log(e, 8888)
          console.log(e.text, 888888)
        }
      )
    },
    //
    async appSource() {
      let res = await getDownUrl({
        planform: this.$route.query.planform ? this.$route.query.planform : ''
      })
      if (res.data.code != 200) return
      this.andriod = res.data.data.andriod_url
      this.ios = res.data.data.ios_url
      const u = navigator.userAgent
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      if (isiOS) {
        this.isios = true
      } else {
        this.isandriod = true
      }
    },
    async clickBtn() {
      var ua = navigator.userAgent.toLowerCase()
      var isWeixin = ua.indexOf('micromessenger') != -1
      if (isWeixin) return (this.isweixin = true)

      if (this.$route.query.invite_code) {
        await this.doCopy(this.$route.query.invite_code)
      }
      const u = navigator.userAgent
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      if (this.$route.query.rid != '' && this.$route.query.rid != undefined) {
        console.log(123)
        console.log(this.$route.query.rid, this.$route.query.store_id)

        if (isiOS) {
          this.show = true // 若是ios 做操作
        } else {
          this.show = true // 要不就是Android
        }
      } else if (
        this.$route.query.store_id != '' &&
        this.$route.query.store_id != undefined &&
        this.$route.query.store_id != 1
      ) {
        if (isiOS) {
          // this.dialogShow = true // 若是ios 做操作
          this.show = true // 若是ios 做操作
        } else {
          this.show = true // 要不就是Android
        }
      } else if (this.$route.query.invite_code) {
        console.log(456)

        if (this.isios) {
          console.log(this.ios) //ios
          window.location.href = 'https://itunes.apple.com/cn/app/id1614216413'
        }
        if (this.isandriod) {
          console.log(this.andriod) //andriod
          window.location.href = this.andriod
        }
      } else {
        // if (isiOS) {
        //   // this.dialogShow = true // 若是ios 做操作
        //   this.show = true // 若是ios 做操作
        // } else {
        //   this.show = true // 要不就是Android
        // }
        if (this.isios) {
          console.log(this.ios) //ios
          window.location.href = 'https://itunes.apple.com/cn/app/id1614216413'
        }
        if (this.isandriod) {
          console.log(this.andriod) //andriod
          window.location.href = this.andriod
        }
      }
    },
    async isTrue() {
      console.log('确定')

      let data = {
        rid: this.$route.query.rid || '',
        uid: this.$route.query.uid,
        phone: this.phone,
        keyCode: this.code,
        store_id: this.$route.query.store_id
      }
      let res = await bindFriend(data)
      console.log(res, 'resres')
      if (res.data.code != 200) {
        this.msg = res.data.message
        console.log(this.msg, 'this.msg')
        this.titleShow = true
        setTimeout(() => {
          this.titleShow = false
        }, 2000)
        return
      }
      if (this.isios) {
        console.log(this.ios) //ios
        window.location.href = 'https://itunes.apple.com/cn/app/id1614216413'
      }
      if (this.isandriod) {
        console.log(this.andriod) //andriod
        window.location.href = this.andriod
      }
      this.show = !this.show
    },
    getCode() {
      if (!this.phone) {
        this.msg = '请输入正确的手机号码'
        console.log(this.msg, 'this.msg')
        this.titleShow = true
        setTimeout(() => {
          this.titleShow = false
        }, 2000)
        return
      }
      let reg = /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/
      if (!reg.test(this.phone)) {
        this.msg = '请输入正确的手机号码'
        console.log(this.msg, 'this.msg')
        this.titleShow = true
        setTimeout(() => {
          this.titleShow = false
        }, 2000)
        return
      }
      this.showCodeImg()
    },
    showCodeImg() {
      // eslint-disable-next-line no-undef
      var captcha1 = new TencentCaptcha('2008794111', (coderes) => {
        console.log(coderes)
        const { randstr, ticket } = coderes
        sendSms({ phone: this.phone, type1: 1, randstr, ticket }).then(
          (res) => {
            console.log(res)
            if (res.data.code == 200) {
              let time = 60
              let timer = setInterval(() => {
                if (time == 0) {
                  clearInterval(timer)
                  this.codeShow = true
                } else {
                  this.codeShow = false
                  this.yzmTitle = time + '秒后重试'
                  time--
                }
              }, 1000)
              this.msg = '获取验证码成功'
              console.log(this.msg, 'this.msg')
              this.titleShow = true
              setTimeout(() => {
                this.titleShow = false
              }, 2000)
            } else {
              this.$toast(res.data.message)
            }
          }
        )
      })
      captcha1.show()
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  height: 100vh;
  width: 100vw;
  background-image: url('../assets/img/live.png');
  background-position: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .boss {
    // width: 7.8rem;
    // height: 16.5rem;
    width: 687.5px;
    height: 1482px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url('../assets/img/file.png');
    background-position: 100%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-top: 0.2rem;
    box-sizing: border-box;
    .video {
      width: 562.5px;
      height: 320px;
      margin-top: 2.2rem;
      .videos {
        width: 562.5px;
        height: 320px;
      }
    }
    .info {
      display: flex;
      flex-direction: column;
      align-items: center;
      .name {
        width: 100%;
        font-size: 0.5rem;
        font-weight: bold;
        color: #945614;
        padding-left: 0.9rem;
      }
      .ulInfo {
        width: 100%;
        font-size: 0.4rem;
        font-weight: 500;
        color: #945614;
        margin-left: 0.29rem;
        li {
          margin: 0.2rem 0;
        }
        .left {
          margin-left: 0.5rem;
          font-weight: 600;
        }
      }
      .more {
        margin-top: 0.2rem;
        font-size: 0.4rem;
        font-weight: 500;
        color: #945614;
        text-align: center;
      }
      .certificate {
        width: 562.5px;
        height: 444.4px;

        // width: 6.4rem;
        // height: 4.5rem;
        border-radius: 0.2rem;
        background: #ffcead;
        margin-top: 0.2rem;
        position: relative;
        overflow: hidden;

        .van-tabs {
          /deep/ .van-tab__pane {
            // height: 3.5rem;
            // height: 81px;
            height: 354px;
            overflow: hidden;
            overflow-y: scroll;
          }
          .none {
            width: 100%;
            // height: 3.2rem;
            height: 354px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 0.4rem;
            color: #945614;
          }
          .infoText {
            // width: 562.5px;
            width: 100%;
            // width: 6rem;
            // height: 3.5rem;
            height: 354px;
            padding: 0 0.2rem;
            overflow: hidden;
            overflow-y: scroll;
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            .content {
              // width: 6rem;
              // width: 562.5px;
              width: 100%;

              background: #fbecd3;
              border-radius: 0.05rem;
              box-sizing: border-box;
              padding: 0 0.3rem;
              margin: 0.15rem 0;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              box-sizing: border-box;
              .time {
                font-size: 0.3rem;
                color: #945614;
                line-height: 1;
                font-weight: 400;
                padding: 0.2rem 0 0.2rem;
              }
              .medicine {
                font-size: 0.35rem;
                color: #945614;
                font-weight: 600;
                line-height: 1;
                padding-bottom: 0.2rem;
              }
            }
          }
          /deep/ .van-tab__text {
            height: 0.8rem;
            font-size: 0.4rem;
            line-height: 0.8rem;
          }

          /deep/ .van-tabs__wrap {
            // height: 0.9rem;
            height: 81px;
            border-bottom: 1px dashed #fbecd3;
          }
          /deep/ .van-tabs__nav {
            background-color: #ffcead;
          }
          /deep/ .van-tabs__line {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0.2rem;
            border-color: transparent transparent #fe1e00 transparent;
            background: none;
          }
        }

        .record {
          height: 0.6rem;
          border-bottom: 1px dashed #d79f6d;
          box-sizing: border-box;
          margin: 0 0.2rem;
          padding: 0 0.2rem;
          font-size: 0.34rem;
          line-height: 0.6rem;
          font-weight: 500;
          color: #f9750c;
        }
      }
    }
    .btn {
      // position: absolute;
      // bottom: -2rem;
      // left: 2.25rem;
      text-align: center;
      margin-top: 0.3rem;
      img {
        // width: 6rem;
        // height: auto;
        width: 4rem;
        height: 1.1rem;
      }
    }
  }
}
::-webkit-scrollbar {
  display: none;
}
.div-content:last-child {
  padding-bottom: 0.4rem;
}
p {
  margin: 0;
  padding: 0;
}
.div-content {
  .time {
    width: 100%;
    font-size: 0.4rem;
    font-weight: 600;
    color: #945614;
    padding: 0.1rem 0 0 0.3rem;
    box-sizing: border-box;
  }
  .infos {
    // width: 6rem;
    // width: 100%;
    width: 528px;
    background: #000;
    margin: auto;
    margin-top: 0.2rem;
    background: rgba(#fbecd3, 1);
    border-radius: 0.05rem;
    // padding: 0 0.3rem;
    box-sizing: border-box;
    .left-right {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // padding-right: 0.3rem;
      padding: 0 0.2rem 0.1rem 0.3rem;
      box-sizing: border-box;
    }
    .displays {
      display: flex;
      flex-wrap: wrap;
      line-height: 1;
      // padding-left: 0.3rem;
      // box-sizing: border-box;
    }
    .clickviope {
      // width: 125px;
      width: 165px;
      height: 48px;
      background: #fbe5ba;
      border: 1px solid #945614;
      border-radius: 23px;
      font-size: 22px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #945614;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
    }
    .info_time {
      font-size: 0.35rem;
      color: #945614;
      padding-left: 0.3rem;
      padding-top: 0.15rem;
      padding-bottom: 0.1rem;
      box-sizing: border-box;
    }
    .info_text {
      font-size: 0.34rem;
      color: #945614;
      padding-top: 0.1rem;
      font-weight: 600;
    }
    .info_text:not(:last-child)::after {
      content: '、';
    }
    .info_text:last-child {
      padding-bottom: 0.2rem;
    }
  }
}

.logoImg {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 0.2rem;
  left: 0.2rem;
  img {
    width: 3.2rem;
    // height: 2.7rem;
    // width: 687.5px;
    object-fit: contain;
    margin-right: 0.5rem;
  }
}
.lookUp {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    height: 1.1rem;
    margin-top: 0.5rem;
  }
}
.van-field {
  width: 6rem;
  height: 1rem;
  border-radius: 0.13rem;
  background: #ffcead;
  color: #945614;
  overflow: hidden;
  /deep/ .van-field__body {
    height: 100%;
    font-size: 0.4rem;
    color: #945614;
  }
  /deep/ .van-field__control {
    color: #945614;
  }
}
.titile {
  font-size: 0.4rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(#000, 0.4);
  padding: 0.2rem 0.5rem;
  color: #fff;
  border-radius: 0.2rem;
  z-index: 999999;
}
.none {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.5rem;
  font-weight: 600;
  color: #945614;
}

// 弹出视频盒子
.van-dialog {
  width: 700px;
  height: 560px;
  // background-color: rgba(0, 0, 0, 0.7);
  // margin: 0 auto;
  background: none;
}
.pot {
  width: 700px;
  height: 560px;
  position: relative;
  // padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  .tc-viope-box {
    // width: 562.5px;
    // height: 320px;
    // background-color: #fff;
    width: 630px;
    // width: 600px;
    height: 460px;
    background: #fbe5ba;
    border-radius: 20px;

    .weiyang {
      width: 630px;
      // width: 600px;
      height: 93px;
      background: #d79f6d;
      border-radius: 20px 20px 0 0;

      font-size: 36px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #935513;
      text-align: center;
      line-height: 93px;
    }
    .bfvipoe {
      width: 540px;
      height: 320px;
      // background: #9fd09a;
      margin: 0 auto;
      margin-top: 23.5px;
      /deep/ #myplayer {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.close {
  width: 100px;
  height: 100px;
  position: absolute;
  // right: 20px;
  right: 0px;
  top: 20px;
  // right: -50px;
  // top: -50px;
  // z-index: 10000000 !important;
}

//
.bossBox {
  width: 8rem;
  height: 6.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    margin: 0;
    padding: 0;
  }
  .verify {
    font-size: 0.53rem;
    color: #333;
    font-weight: 400;
    padding: 0.8rem 0 0.25rem 0;
  }
  .yanzheng {
    width: 7rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .song {
      width: 2.76rem;
      height: 1.15rem;
      background: #43d227;
      border-radius: 0.1rem;
      margin-top: 0.2rem;
      font-size: 0.4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fefefe;
      font-weight: 400;
    }
  }
  .sure {
    width: 4.6rem;
    height: 1.1rem;
    background: #43d227;
    font-size: 0.45rem;
    color: #fefefe;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    margin-top: 0.4rem;
    border-radius: 2rem;
  }
}
.van-cell-group {
  margin-top: 0.2rem;
  width: 7rem;
  .van-cell {
    width: 100%;
    height: 1.2rem;
    display: flex;
    align-items: center;
    background: #f2f2f2;
    border-radius: 0.15rem;
    overflow: hidden;
  }
  /deep/ .van-icon {
    font-size: 0.8rem;
    margin: 0 0.3rem 0 0.34rem;
  }

  /deep/ .van-field__body {
    font-size: 0.41rem;
  }
}
.cell {
  width: 4rem;
  /deep/ .van-icon {
    font-size: 0.65rem;
    margin: 0 0.3rem 0 0.41rem;
  }
}
.titile {
  font-size: 0.4rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(#000, 0.4);
  padding: 0.2rem 0.5rem;
  color: #fff;
  border-radius: 0.2rem;
  z-index: 999999;
}
.isWeixinOpen {
  width: 100vw;
  min-height: 100vh;
  position: fixed;
  background: rgba(#000, 0.4);
  top: 0;
  display: flex;
  justify-content: flex-end;
  img {
    width: 7rem;
    height: 7rem;
    margin-right: 0.2rem;
  }
}
//
</style>
